import React, { Component } from 'react';
import { Counter } from './Counter';
import RamDarbar from '../राम_दरबार.jpg';

export class Home extends Component {
  static displayName = Home.name;

    render () {
        return (
            <div>
                <h1>जय श्री राम</h1>
                <img src={RamDarbar} width="100%" />
                <Counter />
            </div>
        );
    }
}